<template>

  <div class="d-flex pt-5 justify-content-center" style="height: 100vh">
    <div class="p-2 bd-highlight">
      <div class="input-group mb-3">
        <div class="input-group mb-3 d-grid gap-2 mt-10">
          <button type="button" class="btn btn-light btn-lg" @click="newMath">NEW</button>
        </div>
      </div>
      <div class="mb-4 text-center sumary-block" :class="{ 'sumary-check-anim': solutionAnimationVisible }">
        
        <fa icon="far fa-angry" class="sumary-bad" v-if="lastCheckSolution == false" />
        <fa icon="far fa-grin" class="sumary-good" v-if="lastCheckSolution == true" />

      </div>
      <div class="input-group mb-3">
        <input type="text" class="form-control text-end" v-model="mathStr" disabled="disabled">
        <span class="input-group-text">=</span>
        <input type="number" class="form-control" v-model="solutionToCheck" @keypress="solution_keyPress" ref="solutionToCheckRef">
        
        <div class="input-group mb-3 d-grid gap-2 mt-4">
          <button type="button" class="btn btn-primary btn-lg" @click="checkSolution">CHECK</button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  data() {
    return {
      mathStr: '',
      solutionToCheck: 0,
      solution: 0,
      lastCheckSolution: null,
      solutionAnimationVisible: false
    }
  },
  methods: {
    solution_keyPress(e) {
      //console.log(e.charCode);
      if (e.charCode == 13)
        this.checkSolution();
      else if (e.charCode == 96)
        this.newMath();
    },
    checkSolution() {
      this.lastCheckSolution = this.solutionToCheck == this.solution;
      this.$refs.solutionToCheckRef.focus();
      this.$refs.solutionToCheckRef.select();
      this.solutionAnimationVisible = true;
      setTimeout(()=> {
        this.solutionAnimationVisible = false;
      }, 300);
    },
    newMath() {
      var random = this.getRandomInt(0,6);
      console.log(random);
      switch(random) {
        case 0:
          this.mathA();
          break;
        case 1:
          this.mathB();
          break;
        case 2:
          this.mathC();
          break;
        case 3:
          this.mathD();
          break;
        case 4:
          this.mathE();
          break;
        case 5:
          this.mathF();
          break;
        case 6:
          this.mathG();
          break;
      }
      this.$refs.solutionToCheckRef.focus();
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max) + 1;
      return Math.floor(Math.random() * (max - min)) + min;
    },

    mathA() {
      let a = this.getRandomInt(0, 10);
      let b = this.getRandomInt(0, 10);

      this.mathStr = `${a} + ${b}`
      this.solutionToCheck = null;
      this.lastCheckSolution = null;
      this.solution = a + b;
    },
    mathB() {
      let a = this.getRandomInt(0, 5);
      let b = this.getRandomInt(0, 5);
      let c = this.getRandomInt(0, 5);

      this.mathStr = `${a} + ${b} + ${c}`
      this.solutionToCheck = null;
      this.lastCheckSolution = null;
      this.solution = a + b + c;
    },
    mathC() {
      let a = this.getRandomInt(0, 3) * 5;
      let b = this.getRandomInt(0, 10) * 5;

      this.mathStr = `${a} + ${b}`
      this.solutionToCheck = null;
      this.lastCheckSolution = null;
      this.solution = a + b;
    },
    mathD() {
      let a = this.getRandomInt(1, 3) * 5;
      let b = this.getRandomInt(0, 20) * 5;

      if (a < b) {
        let pom = a;
        a = b;
        b = pom;
      }

      this.mathStr = `${a} - ${b}`
      this.solutionToCheck = null;
      this.lastCheckSolution = null;
      this.solution = a - b;
    },
    mathE() {
      let a = this.getRandomInt(0, 20);
      let b = this.getRandomInt(0, 10);

      if (a < b) {
        let pom = a;
        a = b;
        b = pom;
      }

      this.mathStr = `${a} - ${b}`
      this.solutionToCheck = null;
      this.lastCheckSolution = null;
      this.solution = a - b;
    },
    mathF() {
      let a = this.getRandomInt(0, 10);

      this.mathStr = `${a} + ${a} + ${a}`
      this.solutionToCheck = null;
      this.lastCheckSolution = null;
      this.solution = a * 3;
    },
    mathG() {
      let a = this.getRandomInt(0, 5);

      this.mathStr = `${a} + ${a} + ${a} + ${a}`
      this.solutionToCheck = null;
      this.lastCheckSolution = null;
      this.solution = a * 4;
    },
  },
  mounted() {
    this.newMath();
  }
}
</script>

<style>

.sumary-block {
  font-size: 90px;
  height: 130px;
  transform: scale(1);
}

.sumary-good {
  color: green;
}

.sumary-bad {
  color: red;
}

@keyframes WTF {
  from {
    transform: scale(1.3);  
  }
  to {
    transform: scale(1);
  }
}

.sumary-check-anim {
  animation-name: WTF;
  animation-duration: 300ms;
}

</style>
